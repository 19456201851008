











































































































































































































































































































































































































































































































import { Vue, Component } from 'vue-class-decorator'
import { GlobalModule, PricesModule } from '@/store'
import { Payload } from 'vue/interfaces'
import { File } from 'vue/types'

@Component({
  metaInfo: {
    title: 'Создание обьекта'
  },
  components: {
    Geocoder: () => import('@/components/maps/Geocoder.vue'),
    ImageCarousel: () => import('@/components/pages/ImageCarousel.vue')
  }
})
export default class CreatePrices extends Vue {
  public pageID: string

  // $_POST data
  public cords: {
    adress: string;
    lat: number;
    lon: number;
  }

  public images: Array<File>
  public typeId: string
  public userId: string
  public clientsIds: Array<number>
  public objStatusId: string
  public saleStatusId: string
  public price: string
  public cadNumber: string
  public metro: string
  public city: string
  public commonMeters: string
  public kitchenMeters: string
  public placeMeters: string
  public floors: string
  public objectState: string
  public liveMeters: string
  public name: string

  public locality: string
  public additionalExpenses: string
  public aspv: number
  public territoryDocument: string
  public territoryFact: string
  public sizeHouse: string
  public foundation: string
  public wallInsulation: string

  public home: string
  public documentsHouse: number
  public documentsGrounds: number
  public documentsGas: number
  public documentsElectro: number

  public gas: string
  public electro: string
  public water: string
  public sewerage: string

  public facade: string
  public internalFacing: string

  public transportReach: string
  public infrastructure: string
  public specialConditions: string
  public wellConnected: string
  public stock: string
  public meterNumber: string
  public accountNumber: string
  public keysHouse: string
  public curator: string
  public owner: string
  public scheme: string

  // Page data
  public users: Array<{[propName: string]: any}>
  public clients: Array<{[propName: string]: any}>
  public types: Array<{[propName: string]: any}>
  public objectStatuses: Array<{[propName: string]: any}>
  public saleStatuses: Array<{[propName: string]: any}>
  public yesno: Array<{[propName: string]: any}>
  public documents: Array<{[propName: string]: any}>

  // Generate text
  public text: string

  constructor() {
    super()
    this.pageID = '0'

    // Page data
    this.users = []
    this.clients = []
    this.types = []
    this.objectStatuses = []
    this.saleStatuses = []
    this.documents = []
    this.yesno = []

    // Generate text
    this.text = ''

    // $_POST data
    this.cords = {
      adress: '',
      lat: 0,
      lon: 0
    }
    this.images = []
    this.typeId = ''
    this.userId = ''
    this.clientsIds = []
    this.objStatusId = ''
    this.saleStatusId = ''
    this.price = '0'
    this.cadNumber = ''
    this.metro = ''
    this.city = ''
    this.commonMeters = ''
    this.kitchenMeters = ''
    this.placeMeters = ''
    this.floors = ''
    this.objectState = ''
    this.liveMeters = ''
    this.name = ''

    this.locality = ''
    this.additionalExpenses = ''
    this.aspv = 0
    this.territoryDocument = ''
    this.territoryFact = ''
    this.sizeHouse = ''
    this.foundation = ''
    this.wallInsulation = ''

    this.home = ''
    this.documentsHouse = 3
    this.documentsGrounds = 3
    this.documentsGas = 3
    this.documentsElectro = 3

    this.gas = ''
    this.electro = ''
    this.water = ''
    this.sewerage = ''

    this.facade = ''
    this.internalFacing = ''

    this.transportReach = ''
    this.infrastructure = ''
    this.specialConditions = ''
    this.wellConnected = ''
    this.stock = ''
    this.meterNumber = ''
    this.accountNumber = ''
    this.keysHouse = ''
    this.curator = ''
    this.owner = ''
    this.scheme = ''
  }

  // Store init
  @GlobalModule.Getter('getLoading') loading!: boolean
  @PricesModule.Action('getEditPageData') getPageData!: () => Promise<any>
  @PricesModule.Action('createObject') createObject!: (payload: Payload) => Promise<any>

  get generateText() {
    let typeName = ''
    if (this.typeId) {
      const findTypeName = this.types.find((type: any) => type.type_id === this.typeId)
      typeName = 'Продается ' + findTypeName?.title.toLowerCase()
    }

    let price = ''
    if (this.price !== '0' && this.price.trim() !== '' && typeName.trim() !== '') {
      price = ' за ' + this.price + ' рублей'
    }

    let cadNumber = ''
    if (this.cadNumber.trim() !== '') cadNumber = ' кадастровый номер - ' + this.cadNumber + ';'

    let metro = ''
    if (this.metro.trim() !== '') metro = ' удаленность от метро - ' + this.metro + ' м;'

    let city = ''
    if (this.city.trim() !== '') city = ' удаленность от города - ' + this.city + ' км;'

    let commonMeters = ''
    if (this.commonMeters.trim() !== '') commonMeters = ' жилое помещение (кв/м) - ' + this.commonMeters + ';'

    let kitchenMeters = ''
    if (this.kitchenMeters.trim() !== '') kitchenMeters = ' кухня (кв/м) - ' + this.kitchenMeters + ';'

    let placeMeters = ''
    if (this.placeMeters.trim() !== '') placeMeters = ' участок (кв/м) - ' + this.placeMeters + ';'

    let floors = ''
    if (this.floors.trim() !== '') floors = ' этажей - ' + this.floors + ';'

    let liveMeters = ''
    if (this.liveMeters.trim() !== '') liveMeters = ' жилое помещение (кв/м) - ' + this.liveMeters + ';'

    let objectState = ''
    if (this.objectState.trim() !== '') objectState = ' состояние объекта - ' + this.objectState + ';'

    let properties = ''
    if (cadNumber.trim() !== '' || metro.trim() !== '' || city.trim() !== '' || commonMeters.trim() !== '') {
      properties = '. Характеристики:' + cadNumber + metro + city + commonMeters + kitchenMeters + liveMeters + placeMeters + floors + liveMeters + objectState
    }

    this.text = typeName + price + properties
    return this.text
  }

  set generateText(text: string) {
    this.text = text
  }

  save() {
    const payload = {
      name: this.name,
      images: this.images,
      typeId: this.typeId,
      userId: this.userId,
      clientsIds: this.clientsIds,
      objStatusId: this.objStatusId,
      saleStatusId: this.saleStatusId,
      price: this.price,
      cords: `${this.cords.lat}, ${this.cords.lon}`,
      adress: this.cords.adress,
      cadNumber: this.cadNumber,
      metro: this.metro,
      city: this.city,
      commonMeters: this.commonMeters,
      kitchenMeters: this.kitchenMeters,
      placeMeters: this.placeMeters,
      floors: this.floors,
      objectState: this.objectState,
      liveMeters: this.liveMeters,
      text: this.text,

      locality: this.locality,
      additionalExpenses: this.additionalExpenses,
      aspv: this.aspv,
      territoryDocument: this.territoryDocument,
      territoryFact: this.territoryFact,
      sizeHouse: this.sizeHouse,
      foundation: this.foundation,
      wallInsulation: this.wallInsulation,

      home: this.home,
      documentsHouse: this.documentsHouse,
      documentsGrounds: this.documentsGrounds,
      documentsGas: this.documentsGas,
      documentsElectro: this.documentsElectro,

      gas: this.gas,
      electro: this.electro,
      water: this.water,
      sewerage: this.sewerage,

      facade: this.facade,
      internalFacing: this.internalFacing,

      transportReach: this.transportReach,
      infrastructure: this.infrastructure,
      specialConditions: this.specialConditions,
      wellConnected: this.wellConnected,
      stock: this.stock,
      meterNumber: this.meterNumber,
      accountNumber: this.accountNumber,
      keysHouse: this.keysHouse,
      curator: this.curator,
      owner: this.owner,
      scheme: this.scheme
    }

    this.createObject(payload)
      .then(() => {
        this.$noty('success', 'Объект успешно создан', 7000).show()
        this.$router.push('/prices/')
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  goToBack() {
    this.$router.push('/prices/')
  }

  created() {
    this.getPageData()
      .then((res) => {
        this.users = res.users
        this.clients = res.clients
        this.types = res.properties.types
        this.objectStatuses = res.properties.object_statuses
        this.saleStatuses = res.properties.sale_statuses
        this.documents = res.properties.documents
        this.yesno = res.properties.yesno
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }
}
